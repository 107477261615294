a, a:hover, a:link, a:active, a:focus {
    outline: none;
    color: var(--primary-color);
    text-decoration: none;
}

body a:hover {
    text-decoration: none;
    color:var(--primary-font-color);

}

label, input, textarea, select, button {
    font-size: var(--base-font-size);
}

h1, h2, h3, h4, h5 {
    font-weight: normal;
    line-height: normal;
}

h1 { font-size:var(--font-size-xxl);}
h2 { font-size:var(--font-size-xl);}
h3 { font-size:var(--font-size-xl); font-weight:bold;}
h4 { font-size:var(--base-font-size); font-weight:bold;}
h5 { font-size:var(--base-font-size); font-weight:bold;}

h1 small {
    font-size: var(--font-size-s);
    color:var(--primary-font-color);
}


.label-default {
    background: var(--neutral);
    color:var(--primary-font-color);
}

.label-important, .label-warning,
.label-success, .label-info, .label-inverse,
.label-default, .label-primary, .label-danger {
    padding: 2px 8px;
    color: #fff;
    margin-left: 5px;
    border-radius: 3px;
}


.dropdown [class*="label-"]:hover {
    background: var(--dropdown-link-hover-bg) !important;
    color:var(--dropdown-link-hover-color) !important;
}

a .label:after,
a .label-important:after, a .label-warning:after,
a .label-success:after, a .label-info:after, .label-inverse:after,
a .label-default:after, a .label-primary:after {
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: middle;
    border-top: 4px solid #fff;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    content: "";
    margin-left: 2px;
}

a .label.feature-label {
    background: var(--layered-background);
    margin-top: 4px;
    display: block;
    float: right;
    border-radius: 6px;
    padding: 1px 5px;
    color:var(--primary-font-color);
}

a .label.feature-label:after {
    border:none;
    margin:0px;
}

.nav .label.feature-label {
    margin-top:-4px;
}

.pagetitle {
}

.pagetitle h1 {
    font-size: var(--font-size-xl);
    color: var(--main-action-color);
    line-height:60px;
}

.pagetitle h1 a {
    color: var(--main-action-color);
    text-decoration:underline;
}

.pagetitle h5 {
    text-transform: uppercase;
    font-size: 10px;
    color: var(--secondary-font-color);
}

.maincontentinner .subtitle {

    font-size: var(--font-size-xl);
    color: var(--primary-font-color);
    margin-bottom: 10px;
    font-weight:lighter;

}



.subtitle {
    font-size: var(--base-font-size);
    color: #999;
    margin-bottom: 5px;
}

.subtitle2 {
    font-size: var(--base-font-size);
    text-transform: uppercase;
    color: var(--primary-font-color);
    margin-bottom: 5px;
}


blockquote {
    font-family: 'PT Serif', Georgia, "Times New Roman", Times, serif;
    font-style: italic;
    padding-left: 40px;
}

blockquote p {
    margin: 0 !important;
}

blockquote.pull-right {
    background-position: right 5px;
    padding: 0 40px 0 0;
    border-right: 0;
}

.maincontent > h2 {
    color:var(--main-action-color);
}
