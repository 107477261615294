@font-face {
    font-family: 'Atkinson Hyperlegible';
    src: url('~fonts/atkinson/Atkinson-Hyperlegible-Regular-102.ttf') format('truetype'),
    url('~fonts/atkinson/Atkinson-Hyperlegible-Regular-102a.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Atkinson Hyperlegible';
    src: url('~fonts/atkinson/Atkinson-Hyperlegible-BoldItalic-102.ttf') format('truetype'),
    url('~fonts/atkinson/Atkinson-Hyperlegible-BoldItalic-102a.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;

}

@font-face {
    font-family: 'Atkinson Hyperlegible';
    src: url('~fonts/atkinson/Atkinson-Hyperlegible-Bold-102.ttf') format('truetype'),
    url('~fonts/atkinson/Atkinson-Hyperlegible-Bold-102a.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'Atkinson Hyperlegible';
    src: url('~fonts/atkinson/Atkinson-Hyperlegible-Italic-102.ttf') format('truetype'),
    url('~fonts/atkinson/Atkinson-Hyperlegible-Italic-102a.woff2') format('woff2');

    font-weight: normal;
    font-style: italic;

}



