/* Ticket Labelled dropdowns */

.dropdown-menu {
    margin-top: 0;
    border-radius: var(--box-radius);
    border:0px;
    box-shadow: var(--regular-shadow);
    background:var(--secondary-background);
}

.bigMenu ul li {
    list-style-type: none;
}

.dropdown-menu > li > a,
.bigMenu ul li a{
    font-size: var(--base-font-size);
    color:var(--primary-font-color);
    margin: 0 5px;
    padding: 5px 10px;
}
.pagetitle .dropdown-menu > li > a {
    color:var(--primary-font-color);
    text-decoration:none;
}


.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus {
    color: var(--dropdown-link-hover-color);
    background:var(--dropdown-link-hover-bg);
}

.dropdownTabs {
    padding:5px;
    display: flex;
    flex-direction: row;

    justify-content: space-around;
    flex-wrap: wrap;

    align-content: space-between;
    border-bottom:1px solid var(--main-border-color);
}

.dropdownTabs a {

}

.dropdownTabs a:hover {
    text-decoration: none;
}



.maincontentinner .viewDropDown .dropdown-menu > li > a:hover .muted,
body .maincontentinner .viewDropDown .dropdown-menu li a.active,
.dropdown-menu > li > a.active,
.dropdown ul li.active a{
    color: var(--dropdown-link-hover-color);
    background:var(--dropdown-link-hover-bg);
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a,
.bigMenu ul li a,
.bigMenu ul li a:hover,
.bigMenu ul li a:focus {
    color: var(--dropdown-link-hover-color) !important;
    background:var(--dropdown-link-hover-bg) !important;
}

.dropdown-menu > li > a:hover small,
.dropdown-menu > li > a:focus small,
.dropdown-submenu:hover > a small,
.dropdown-submenu:focus > a small,
.bigMenu ul li a small,
.bigMenu ul li a:hover small,
.bigMenu ul li a:focus small {
    color: var(--dropdown-link-hover-color) !important;
}


.dropdown .dropdown-menu .nav-header {
    padding-left: 10px;
    padding-right: 10px;
    font-size:var(--font-size-m);
    color:var(--primary-font-color);
    text-transform:none;
}

.ticketDropdown.show {
    display:inline-block;
}

.ticketDropdown {
    font-size:var(--font-size-m);

}

.ticketDropdown ul,
.ticketDropdown ul li a{
    top: 22px;
    font-size:var(--font-size-m);
}

.ticketDropdown.userDropdown {
    margin-top:-2px;
}

.ticketDropdown.userDropdown ul {
    top: 33px;
    left: 6px;
}

.dropRight ul,
.ticketDropdown.userDropdown.dropRight ul{
    right:0px;
    left:auto;
}

.maincontent .ticketDropdown > a,
.maincontent .ticketDropdown > a:link,
.nyroModalCont .ticketDropdown > a,
.nyroModalCont .ticketDropdown > a:link{
    color:#fff;
    padding:0px 10px;
    white-space: nowrap;
    border-radius:var(--box-radius-large);
    margin-left:0px;
    box-shadow:var(--min-shadow);
}

.maincontent .ticketDropdown.userDropdown > a,
.maincontent .ticketDropdown.userDropdown > a:link,
.nyroModalCont .ticketDropdown.userDropdown > a,
.nyroModalCont .ticketDropdown.userDropdown > a:link {
    padding-left:0px;
}

.maincontent .ticketDropdown.firstDropdown > a {
    margin-left:0px;
}

.maincontent .ticketDropdown.lastDropdown > a {
    padding-right:0px;
}

.maincontent .ticketDropdown > a:hover,
.maincontent .ticketDropdown > a:focus {
    text-decoration: none;
}


.maincontent .ticketDropdown.colorized li a,
.nyroModalCont .ticketDropdown.colorized li a{
    color:#fff;
}

.maincontent .ticketDropdown.noBg {
    background:transparent;


}

.maincontent .ticketDropdown.noBg a {
    box-shadow: none;
}

.maincontent .ticketDropdown.noBg >a img {
    margin-top:-1px;
}

.maincontent .dropdown img {
    width:23px;
    border-radius: 12px;
    border: 1px solid var(--neutral);
    margin-top:1px;
    padding:1px;
}


.maincontent .ticketDropdown.noBg >a:hover {
    color:#000;
}

.maincontent .ticketDropdown.noBg >a:hover img {
    border-color:var(--dropdown-link-hover-bg) !important
}

.maincontent .ticketDropdown ul li a[class*="label-"],
.nyroModalCont .ticketDropdown ul li a[class*="label-"] {
    padding: 5px 10px;
    display:block;
    border-radius: 0px;
}

.chosen-results li[class*=" label-"] {
    color:#fff;
}

.chosen-results li[class*=" label-"]:hover {
    color:#555;
}

.chosen-results li.label-warning,
.maincontent .ticketDropdown a.label-warning,
.nyroModalCont .ticketDropdown a.label-warning,
.label-warning,
.label-yellow{
    background:var(--yellow);
    color:var(--yellow-text-color);
}

.chosen-results li.label-info,
.maincontent .ticketDropdown a.label-info,
.nyroModalCont .ticketDropdown a.label-info{
    background:var(--dark-blue);
    color:var(--dark-blue-text-color);
}

.chosen-results li.label-important,
.maincontent .ticketDropdown a.label-important,
.nyroModalCont .ticketDropdown a.label-important,
.label-important{
    background:var(--red);
    color:var(--red-text-color);
}

.chosen-results li.label-danger,
.maincontent .ticketDropdown a.label-danger,
.nyroModalCont .ticketDropdown a.label-danger,
.label-danger,
.label-red{
    background:var(--dark-red);
    color:var(--dark-red-text-color);
}

.chosen-results li.label-success,
.maincontent .ticketDropdown a.label-success,
.nyroModalCont .ticketDropdown a.label-success,
.label-success,
.label-green {
    background:var(--green);
    color:var(--green-text-color);
}

.chosen-results li.label-default,
.maincontent .ticketDropdown a.label-default,
.nyroModalCont .ticketDropdown a.label-default,
.label-default{
    background: var(--grey);
    color:var(--grey-text-color);
}

.chosen-results li.label-default.milestone,
.maincontent .ticketDropdown a.label-default.milestone,
.nyroModalCont .ticketDropdown a.label-default.milestone {
    color:var(--blue-text-color);
}

.chosen-results li.label-purple,
.maincontent .ticketDropdown a.label-purple,
.nyroModalCont .ticketDropdown a.label-purple{
    background:var(--purple);
    color:var(--purple-text-color);
}

.chosen-results li.label-pink,
.maincontent .ticketDropdown a.label-pink,
.nyroModalCont .ticketDropdown a.label-pink{
    background:var(--pink);
    color:var(--pink-text-color);
}

.chosen-results li.label-brown,
.maincontent .ticketDropdown a.label-brown,
.nyroModalCont .ticketDropdown a.label-brown{
    background:var(--brown);
    color:var(--brown-text-color);
}

.chosen-results li.label-blue,
.maincontent .ticketDropdown a.label-blue,
.nyroModalCont .ticketDropdown a.label-blue{
    background:var(--blue);
    color:var(--blue-text-color);
}

.chosen-results li.label-dark-blue,
.maincontent .ticketDropdown a.label-dark-blue,
.nyroModalCont .ticketDropdown a.label-dark-blue{
    background:var(--dark-blue);
    color:var(--dark-blue-text-color);
}

.chosen-results li.label-darker-blue,
.maincontent .ticketDropdown a.label-darker-blue,
.nyroModalCont .ticketDropdown a.label-darker-blue{
    background:var(--darker-blue);
    color:var(--darker-blue-text-color);
}

.chosen-results li.label-green,
.maincontent .ticketDropdown a.label-green,
.nyroModalCont .ticketDropdown a.label-green{
    background:var(--green);
    color:var(--green-text-color);
}

.chosen-results li.label-dark-green,
.maincontent .ticketDropdown a.label-dark-green,
.nyroModalCont .ticketDropdown a.label-dark-green{
    background:var(--dark-green);
    color:var(--dark-green-text-color);
}

.chosen-results li.label-red,
.maincontent .ticketDropdown a.label-red,
.nyroModalCont .ticketDropdown a.label-red{
    background:var(--red);
    color:var(--red-text-color);
}

.chosen-results li.label-dark-red,
.maincontent .ticketDropdown a.label-dark-red,
.nyroModalCont .ticketDropdown a.label-dark-red{
    background:var(--dark-red);
    color:var(--dark-red-text-color);
}

.chosen-results li.label-grey,
.maincontent .ticketDropdown a.label-grey,
.nyroModalCont .ticketDropdown a.label-grey{
    background:var(--grey);
    color:var(--grey-text-color);
}

.maincontent .ticketDropdown a.label-default.effort,
.nyroModalCont .ticketDropdown a.label-default.effort{
    background:var(--secondary-background);
    color:var(--primary-font-color);

}


.maincontent .ticketDropdown ul li {
    margin-bottom:1px;
}

.maincontent .ticketDropdown.milestoneDropdown li a:hover{
    background: var(--dropdown-link-hover-bg) !important;
    color: var(--dropdown-link-hover-color) !important;
}

.maincontent .accordionTitle {
    font-size: var(--font-size-l);
    color: var(--primary-font-color);
    margin-bottom: 10px;
    font-weight:bold;
    margin-left:0px;
    border-radius: var(--accordion-header-radius);
    padding: 5px 0px;
}

.maincontent .noBackground .accordionTitle a {
    color: var(--main-action-color);
}

.maincontent .accordionTitle i {
    display:inline-block;
    width:10px;
    text-align:center;
    margin-right:10px;
}

.maincontent .accordionTitle[class*="label-"] {
    margin-left:0px;
    border-radius: var(--accordion-header-radius);
    padding: 5px 0px;
    background: none;
}

.maincontent .accordionTitle.label-warning a{
    background:none;
    color:var(--yellow);
}

.maincontent .accordionTitle.label-important a {
    background:none;
    color:var(--red);
}

.maincontent .accordionTitle.label-info a {
    background:none;
    color:var(--dark-blue);
}

.maincontent .accordionTitle.label-danger a {
    background:none;
    color:var(--dark-red);
}

.maincontent .accordionTitle.label-success a {
    background:none;
    color:var(--green);
}

.maincontent .accordionTitle.label-default a {
    background:none;
    color:var(--grey);
}

.simpleAccordionContainer {
    margin-bottom:30px;
    padding-left: 20px;
}

.simpleAccordionContainer.kanban {
    padding-left:0px;
}

.maincontent .accordionTitle .accordion-toggle i.fa:first-child {
    width: 15px;
    margin-top: 0px;
    margin-right: 5px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    border-radius: var(--element-radius);
    font-size: var(--font-size-m);
}

.maincontent .accordionTitle .accordion-toggle i.fa:first-child:hover {
    background: var(--main-menu-link-active-bg);
}


.ticketDropdown ul::after {
    position: absolute;
    top:-6px;
    left: 10px;
    transform: rotate(0deg);
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid white;
    border-left: 7px solid transparent;
    content: '';
}

.dropdown-menu.pull-right::after {
    right: 10px;
    left:auto;
}

.viewDropDown .dropdown-menu {
    right:0px;
    left:auto;
}

.viewDropDown .dropdown-menu li a.active{

}

.viewDropDown .dropdown-menu li a.active:hover {
    color:#fff;
}

.dropdown-menu span.radio input[type="radio"] {
    float:left;
    vertical-align: middle;
    margin-top: 9px;
    margin-right:5px;
}

.dropdown-menu span.radio {
    display:block;
    font-size: 12px;
    color: var(--main-menu-link-color);
    margin: 0 5px;
    padding: 5px 10px;
}

.dropdown-menu span.radio:hover {
    color: var(--dropdown-link-hover-color);
    background:var(--dropdown-link-hover-bg);
}

.dropdown-menu span.radio label {
    display:inline-block;
    width:100%;
}

.wikiSelect {
    margin-bottom:5px;
}

.dropdown-menu.iconpicker-container,
.iconpicker-popover.popover {
    width:215px;
    min-width:215px;
}

.iconpicker-popover .popover-title {
    display:none;
}

.btn-group .btn.titleIconPicker {
    font-size: var(--font-size-xl);
    line-height: 21px;
    padding: 5px 6px;
    color: var(--primary-font-color);
    background-color: var(--secondary-background);
    border: 1px solid var(--secondary-background);
    border-radius: 3px;
}

.btn-group .btn.titleIconPicker:hover {
    border: 1px solid var(--main-border-color);
}

.btn-group .btn.titleIconPicker .caret,
.btn-group .btn.titleIconPicker .caret,
.btn-group .btn.titleIconPicker:last-child .caret,
.btn-group >.btn.titleIconPicker.dropdown-toggle .caret {
    border-top-color: var(--primary-font-color);
}

.btn-group .btn.titleIconPicker:hover .caret,
.btn-group .btn.titleIconPicker:hover .caret,
.btn-group .btn.titleIconPicker:last-child:hover .caret,
.btn-group >.btn.titleIconPicker.dropdown-toggle:hover .caret {
    border-top-color: var(--secondary-action-hover-color);
}

.inlineDropDownContainerLeft {
    float:left;
    display:inline-block;
}

.pagetitle .header-title-dropdown {
    border: 1px solid transparent;
    display:inline-block;
    background:transparent;
    width:auto;
    color:var(--main-action-color);
    text-decoration:underline;
    padding:0px 3px ;
    border-radius: var(--box-radius-small);
}

.pagetitle .header-title-dropdown .fa {
    padding-left:10px;
}

.pagetitle .header-title-dropdown:hover {
    border: 1px solid var(--main-action-color);
}

.dropdownWrapper {
    position:relative;
}

.dropdownWrapper .border {
    border-top:1px solid var(--neutral);
}

.headerEditDropdown {
    float:right;
    padding-top:20px;
}

.pagetitle .btn-transparent {
    background:transparent;
    color:var(--main-action-color);
    border:1px solid var(--main-action-color);
    font-size:var(--font-size-l);
}

.editCanvasDropdown {
    right:0px;
    left:auto;
}


.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus {
    background:var(--main-menu-link-active-bg);
    color:var(--main-menu-link-active-color);
}

.dropdown-menu .scroll-wrapper {
    height:700px;
    overflow:scroll;
}

.inlineDropDownContainer {
    float:right;
    position:relative;
}

.inlineDropDownContainer > a {
    color: #aaa;
    width: 24px;
    line-height: 20px;
    text-align: center;
    padding-top: 1px;
    border-radius: 5px;
}

.inlineDropDownContainer > a.btn {
    width:auto;
}

.inlineDropDownContainer > a:last-child {
    margin-right:-5px;
}

.inlineDropDownContainer > a:hover {
    color: var(--accent1);
    background: var(--col-hover-bg);
}

.inlineDropDownContainer .ticketDropDown {
    float:right;
    font-size: var(--font-size-xl);
    color: var(--interactive-icon-color);
    margin-right:-5px;
}

.inlineDropDownContainer .dropdown-menu {
    right:0px;
    left:auto;
}

.inlineDropDownContainer.dropright .dropdown-menu {

    left:-5px;
}

.inlineDropDownContainer .dropdown-menu .border {
    border-top:1px solid var(--neutral);
}

.inlineDropDownContainer .dropdown-menu li>span {
    padding-left:15px;
}

.grid-stack-item-content .inlineDropDownContainer .ticketDropDown,
.grid-stack-item-content .grid-handler-top{
    color:var(--main-action-color);
}

.grid-stack-item-content.maincontentinner .inlineDropDownContainer .ticketDropDown,
.grid-stack-item-content.maincontentinner .grid-handler-top {
    color: var(--interactive-icon-color);
}
