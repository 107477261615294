
/* login */
.inputwrapper button { background: var(--main-action-bg); border-color: var(--main-border-color) }
.inputwrapper button:hover { background: var(--main-action-hover-bg); border-color: var(--main-border-color) }

/* background */
.leftmenu .nav-tabs.nav-stacked > li.active > a,
.leftmenu .nav-tabs.nav-stacked > li.active > a:hover,
.shortcuts li a,
.widgettitle,
.mediamgr .mediamgr_rightinner h4,
.messagemenu, .msglist li.selected,
.wizard .hormenu li a.done,
.wizard .hormenu li a.selected,
.actionBar a:hover, .actionBar a:hover,
.wizard .tabbedmenu, .nav-tabs > .active > a:focus,
.tabbable > .nav-tabs,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus,
.nav-pills > .active > a,
.nav-pills > .active > a:hover,
.nav-pills > .active > a:focus,
.tabs-right .nav-tabs,
.tabs-right > .nav-tabs > li > a,
.tabs-left .nav-tabs,
.tabs-left > .nav-tabs > li > a,
.progress-primary .bar,
.ui-datepicker-calendar td.ui-datepicker-today a,
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus,
.nav-list > .active > a,
.nav-list > .active > a:hover,
.nav-list > .active > a:focus,
.fc-widget-header, .fc-widget-header.fc-agenda-gutter.fc-last, .chosen-container-multi .chosen-choices .search-choice,
div.tagsinput span.tag, .chosen-container .chosen-results .highlighted
{ background-color: var(--primary-color) }



.loginpanelinner,
.leftpanel .leftmenu .nav-tabs ul.projectselector li.active a{
    background-color: var(--primary-color) !important;
    color:var(--main-action-color);
}

.header .logo {

}

.boxedHighlight{
    background:var(--accent1);
}

/* color */

a,a:hover,a:link,a:active,a:focus,
.userlist li .uinfo h5,
.messagemenu ul li.active a,
.msglist li h4,
.actionBar a,
.actionBar a.buttonDisabled,
.wizard .tabbedmenu li a.selected,
.wizard .tabbedmenu li a.done,
.tabbable > .nav-tabs > li.active > a,
.btn-circle.btn-primary, .btn-circle.btn-primary:hover, .btn-circle.btn-primary:focus,
.btn-circle.btn-primary:active, .btn-circle.btn-primary.active,
.btn-circle.btn-primary.disabled, .btn-circle.btn-primary[disabled],
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus,
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus,
.ticketBox  a.userPopover:hover,
.primaryColor,
.optionLink,
.inlineDropDownContainer .ticketDropDown:hover,
.leftpanel .leftmenu .nav-tabs ul.projectselector  li.intro a,
.maincontentinner .ticketDropdown.noBg >a,
.maincontentinner .ticketDropdown.noBg >a:link,
.viewDropDown .dropdown-menu li a.active,
.paginate_button.current:hover
{ color: var(--primary-color); }


input[type='submit'],
button,
.shepherd-element.shepherd-theme-arrows .shepherd-content footer .shepherd-buttons li .shepherd-button,
.shepherd-element.shepherd-theme-arrows.shepherd-has-title .shepherd-content header,
.paginate_button.current
{ background-color:  var(--main-action-bg);  }

input[type='submit']:hover,
button:hover {
    background-color:  var(--main-action-hover-bg);
    color:#fff;
}

.chosen-container-multi .chosen-choices .search-choice {
    color:#fff;
}

.btn-white {
    background:#fff;
}

span.btn-white:hover{
    background:#fff;
}


/* border color */
.widgetcontent,
.messagemenu ul li.active,
.messageleft,
.messageright,
.messagesearch,
.msgreply,
.wizard .hormenu li a,
.wizard .hormenu li:first-child a,
.stepContainer,
.actionBar,
.actionBar a,
.actionBar a.buttonDisabled,
.tabbable > .nav-tabs,
.tabbable > .tab-content,
.nav-tabs.nav-stacked > li > a:focus,
.btn-circle.btn-primary, .btn-circle.btn-primary:hover, .btn-circle.btn-primary:focus,
.btn-circle.btn-primary:active, .btn-circle.btn-primary.active,
.btn-circle.btn-primary.disabled, .btn-circle.btn-primary[disabled],
.nav-tabs,
.nav-tabs > li > a:hover, .nav-tabs > li > a:focus,
.tabs-below .tab-content,
.tabs-below > .nav-tabs > li.active > a,
.tabs-right,
.tabs-left,
.tab-primary.ui-tabs,
.nav-tabs.nav-stacked > li > a,
.nav-tabs.nav-stacked > li > a:hover,
.nav-tabs.nav-stacked > li > a:hover,
.nav-tabs.nav-stacked > li > a:focus,
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus,
div.tagsinput span.tag
{ border-color: var(--main-border-color) }


.ui-datepicker-header { background-color: var(--primary-color) !important; }
.ui-datepicker { border-color: var(--main-border-color) !important; }


/* extras */

.tabs-below > .nav-tabs > li.active > a { border-bottom: 1px solid var(--main-border-color) !important; }
.nav-list > li > a { color: #666; }
.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li { border-color: var(--main-border-color); }
.leftmenu .nav-tabs.nav-stacked > li > a { border-color: var(--main-border-color) !important; }
.leftmenu .nav-tabs.nav-stacked > li.active > a { border-color: var(--main-border-color) !important; }


@media screen and (max-width: 480px) {

 .userloggedinfo ul li a:hover { background-color: var(--primary-color); }

  .userloggedinfo .userinfo,
  .wizard .hormenu li,
  .messageright { border-color: var(--main-border-color); }

}

.timesheetTable {

}

.timesheetTable input {
    width:70%;
}

.week-picker {
    width:100px;
}

.timesheetTable select {
    width:90%;
}


.ui-weekpicker td a.ui-state-hover{
    background:#eee;
}

.ui-weekpicker td a.ui-state-highlight {
    background-color: var(--primary-color);
}


.ui-state-highlight {
    background:#eee;
}

.companyProject,
.companyProject a{
    color:#999;
    font-weight:normal
}

.filterBar {

    border:0px solid var(--main-border-color);
    padding:10px;
    margin-bottom:5px;
    padding-top:5px;
    margin-top: 10px;
    background:var(--secondary-background);
    border-radius:var(--box-radius);
    z-index: 10;
    position: absolute;
    left: 0px;
    box-shadow: var(--large-shadow);
}

.filterBar label {
    font-weight:normal;
}

.headtitle {
    background:var(--kanban-col-bg);
    border:0px solid var(--main-border-color);
    border-radius:var(--box-radius);
}

.filterBar .filterBoxLeft {
    float:left;
    margin-right:15px;
    min-width:50px;
}

.filterBar .filterBoxLeft input,
.filterBar .filterBoxLeft select {
    float:left;
}

label.inline {
    float:left;
    margin-right:5px;
    padding-top:6px;
}

.loading {
    position:absolute;
    width:100%;
    height:40px;
}

body .widgettitle.title-light {
    color:var(--primary-color);
    padding-left:0px;
    background-color: transparent !important;
    border-left:0px !important;
    border-top:0px !important;
    border-right:0px !important;
    border-bottom:0px solid #ccc;
    margin-bottom:15px;
    font-size:var(--font-size-l);
    box-shadow: none;

}

.nyroModalCont .widgettitle.title-light {
    padding-top: 5px;
    font-size:var(--font-size-l);
}

div.tagsinput {
    border:1px solid var(--main-border-color);
}


body .tox .tox-edit-area__iframe,
body .tox .tox-toolbar-overlord{
    background-color:transparent;
}


.ui-datepicker-next {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 5px;
    background: url(~images/calarrow.png) no-repeat 3px 1px;
}

.ui-datepicker-prev {
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 5px;
    background: url(~images/calarrow.png) no-repeat 3px -39px;
}

.ui-datepicker-header {
    position: relative;
    text-align: center;
    padding: 5px;
    color: #fff;
    background: var(--primary-color);
    text-transform: uppercase;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.ui-datepicker {
    border-radius:5px;
}


div.growl,
div.growl.growl-default,
div.growl div.growl-closer {
    background:var(--secondary-background);
    color:var(--primary-font-color);
    padding:20px;
    font-size:var(--base-font-size);
    width:250px;
    opacity:1;
    border-radius:var(--box-radius);
    box-shadow: var(--regular-shadow);
    border:0px;

}

div.growl growl-close {
    color:var(--primary-font-color);
    font-size:var(--base-font-size);
}

div.growl.growl-default,
div.growl.growl-success{
    border-left:5px solid var(--green);
}

div.growl.growl-notice {
    border-left:5px solid var(--blue);
}

div.growl.growl-error {
    border-left:5px solid var(--dark-red);
}

div.growl .growl-title {
    font-size: 18px;
    line-height: 24px;
    display:none;
}

div.growl .growl-message {
    font-size: var(--base-font-size);
    line-height: 18px;
    font-family: var(--primary-font-family);
}

div.growl.growl-medium {
    width: 250px;
    padding: 20px;
    margin: 10px;
}

/* Playing with header */


:not(pre)>code[class*=language-], pre[class*=language-] {
}

code[class*=language-], pre[class*=language-] {
    text-shadow:0px 1px var(--secondary-background);
}


element.style {
    margin-top: 5px;
    margin-bottom: 15px;
}
body hr {
    margin: 20px 0;
    border: 0;
    border-bottom: 1px solid var(--main-border-color);
}

/* tippy overrides
 */

.tippy-content {
    font-size:var(--font-size-s);
    color: var(--secondary-action-hover-color);
}

.tippy-box {
    background: var(--secondary-action-hover-bg);
}

.tippy-arrow {
    color:var(--secondary-action-hover-bg);
}


.ui-resizable-se {
    opaciy:0.5
}

#freshworks-container,
#freshworks-container #launcher-frame{
    z-index:90 !important;
}
