
body .jstree-default .jstree-wholerow-hovered,
body .jstree-default .jstree-wholerow-clicked{
    background:var(--main-menu-overlayed-link-hover-bg);
    border-radius:10px;
}

body .jstree-default .jstree-clicked {
    color:var(--main-menu-link-hover-color);
}

.jstree-default .jstree-node {
    line-height:34px;
    min-height:34px;
}

body .jstree-default .jstree-node a{
    padding-top:5px;
    padding-bottom:5px;
}


body .jstree-default .jstree-node .jstree-wholerow {
    height:34px;
}

#article-toc-wrapper {

}

body .jstree-default > .jstree-no-dots .jstree-closed > .jstree-ocl {
    background-position: -9px 1px;
}

.jstree-default > .jstree-no-dots .jstree-open > .jstree-ocl {
    background-position: -40px 1px;
}

.jstree-default .jstree-icon:empty {
    width:20px;
    padding-right:5px;
    opacity: 0.6;
}

.jstree-wholerow-ul {
    max-width:100%;
    overflow:hidden;
}
