@font-face {
    font-family: 'Shantell Sans';
    src: url('~fonts/shantell/Shantell_Sans-Informal_Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Shantell Sans';
    src: url('~fonts/shantell/Shantell_Sans-Informal_Bold_Italic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;

}

@font-face {
    font-family: 'Shantell Sans';
    src: url('~fonts/shantell/Shantell_Sans-Informal_Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'Shantell Sans';
    src: url('~fonts/shantell/Shantell_Sans-Informal_Regular_Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;

}



